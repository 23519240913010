<template>
  <div class="text-start px-5 py-4">
    <div class="d-flex overflow-auto my-2">
      <button
        class="btn-outline primary-btn me-3 d-flex my-1 flex-shrink-0"
        @click="createOrder"
      >
        <p>{{ $t("borrow.request_borrow") }}</p>
      </button>
      <button
        v-if="isBorrowReturnAdmin"
        class="btn-outline primary-btn me-3 d-flex my-1 flex-shrink-0"
        @click="$router.push({ name: 'add-borrow' })"
      >
        <p>{{ $t("borrow.add_borrow_asset") }}</p>
      </button>
      <button
        v-if="isBorrowReturnAdmin"
        class="btn-outline danger-btn me-3 d-flex my-1 flex-shrink-0"
        @click="toRemoveBorrowAsset"
      >
        <img class="me-2" src="../../assets/delete_bin.svg" alt="" />

        <p>{{ $t("borrow.remove_borrow_asset") }}</p>
      </button>
    </div>
    <div class="d-flex justify-content-between align-items-end flex-wrap">
      <div class="d-flex flex-wrap">
        <div class="d-flex flex-column m-2">
          <p style="color: #7c7c7c;">{{ $t("borrow.borrow_filter_start") }}</p>
          <date-picker
            v-model="filter.start_datetime"
            locale="en"
            mode="dateTime"
            is24hr
            :minute-increment="60"
            :masks="masks"
            :model-config="modelConfig"
            @input="loadAsset"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                readonly
                @click="togglePopover()"
              />
            </template>
          </date-picker>
        </div>
        <div class="d-flex flex-column m-2">
          <p style="color: #7c7c7c;">{{ $t("borrow.borrow_filter_end") }}</p>
          <date-picker
            v-model="filter.end_datetime"
            locale="en"
            mode="dateTime"
            is24hr
            :minute-increment="60"
            :min-date="filter.start_datetime"
            :validHours="hourIsValidEnd"
            :masks="masks"
            :model-config="modelConfig"
            @input="loadAsset"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                readonly
                @click="togglePopover()"
              />
            </template>
          </date-picker>
        </div>
      </div>
      <div class="d-flex align-items-center flex-wrap">
        <div>
          <b-dropdown id="dropdown-1" :text="statusText">
            <b-dropdown-item
              v-for="(status, index) in statusList"
              :key="index"
              @click="handleStatusFilter(status.value)"
            >
              <div class="d-flex align-items-center gap-3">
                <div
                  class="status-dot"
                  :style="{ backgroundColor: status.color }"
                ></div>
                <div>
                  {{ status.label }}
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="d-flex align-items-center">
          <div class="position-relative m-2" style="height: fit-content">
            <input
              v-model="filter.text"
              v-debounce:700ms="loadAsset"
              type="text"
              :placeholder="$t('borrow.search')"
              class="form-control"
              style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
            />
            <img
              class="position-absolute top-50 translate-middle-y"
              style="left: 5px"
              src="../../assets/search-white.svg"
              width="20px"
            />
          </div>
          <button
            class="filter flex-shrink-0"
            type="button"
            id="button-addon2"
            @click="filterModal = true"
          >
            <img src="@/assets/filter-white.svg" />
          </button>
        </div>
      </div>
    </div>
    <hr class="hr" />
    <borrow-list-table
      :assetData="tableData"
      :otherColumn="otherColumn"
      :page="currentPage"
      :perPage="perPage"
      :rowSelection="'none'"
      @note-click="showAssetNote"
      @interface="getBorrowTableInterface"
    ></borrow-list-table>
    <div class="d-flex justify-content-center mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
        @input="loadAsset"
      ></b-pagination>
    </div>

    <base-dialog
      :showModal="noteModal"
      hideButton
      showCloseButton
      @hide-modal="noteModal = false"
    >
      <div class="mx-3">
        <p class="text-center">{{ $t("borrow.note") }}</p>
        <div class="asset-info my-4">
          <p class="label">{{ $t("borrow.status_note") }}</p>
          <p>
            {{
              notAvalilableStatusTranslate(
                assetDetailInModal.not_available_note
              )
            }}
          </p>
        </div>
      </div>
    </base-dialog>

    <asset-filter
      :showModal="filterModal"
      @set-filter="setDefaultFilter"
      @confirm-action="handleFilter"
      @cancel-action="handleClear"
      @hide-modal="filterModal = false"
    ></asset-filter>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { authHeader } from "../../store/actions";
import { mapGetters } from "vuex";
import { borrowUrl } from "@/util/backend";

import AssetFilter from "../../components/BorrowReturn/AssetFilter.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import BorrowListTable from "../../components/Table/BorrowReturn/BorrowListTable.vue";
import BaseDialog from "../../components/BaseDialog.vue";
import { AssetStatuses } from "../../constants/borrow-return/AssetStatuses";
import { statusOptionsMixin } from "@/mixin/BorrowReturn/statusOptionsMixin";

export default {
  components: {
    DatePicker,
    BorrowListTable,
    BaseDialog,
    AssetFilter,
  },
  mixins: [statusOptionsMixin],
  data() {
    return {
      AssetStatuses,
      modelConfig: {
        type: "string",
      },
      masks: {
        inputDateTime24hr: "DD/MM/YYYY HH:mm",
      },
      statusList: [
        {
          label: this.$t("borrow.free_status"),
          value: AssetStatuses.AVAILABLE,
          color: "#17d7b2",
        },
        {
          label: this.$t("borrow.occupied_status"),
          value: AssetStatuses.UNAVAILABLE,
          color: "#ffa53a",
        },
        {
          label: this.$t("borrow.not_ready_status"),
          value: AssetStatuses.NOT_AVAILABLE,
          color: "#e13434",
        },
      ],
      currentPage: 1,
      perPage: 10,
      filterModal: false,
      filter: {
        request_status: null,
        start_datetime: moment()
          .startOf("h")
          .toISOString(),
        end_datetime: moment()
          .add(1, "hours")
          .startOf("h")
          .toISOString(),
        text: null,
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
      },
      filterOptions: null,

      BorrowListTableFunction: {
        getSelectedRowData: () => {},
        showLoading: () => {},
        hideLoading: () => {},
      },
      tableData: null,
      otherColumn: [],
      totalRows: 0,

      addAssetModal: false,
      selectedAsset: null,
      noteModal: false,
      assetDetailInModal: {},
    };
  },
  computed: {
    ...mapGetters({
      isBorrowReturnAdmin: "isBorrowReturnAdmin",
      getfilterOption: "getfilterOption",
    }),
    statusText() {
      switch (this.filter.request_status) {
        case AssetStatuses.AVAILABLE:
          return this.$t("borrow.free_status");
        case AssetStatuses.UNAVAILABLE:
          return this.$t("borrow.occupied_status");
        case AssetStatuses.NOT_AVAILABLE:
          return this.$t("borrow.not_ready_status");
        default:
          return this.$t("borrow.borrow_status_text");
      }
    },
  },
  methods: {
    notAvalilableStatusTranslate(value) {
      switch (value) {
        case "DAMAGED":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.DAMAGED"
          )}`;
        case "LOST":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.LOST"
          )}`;
        case "OVERDUE":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.OVERDUE"
          )}`;
        default:
          return "-";
      }
    },
    hourIsValidEnd(hour, dateParts) {
      if (moment(dateParts.date).isSame(this.filter.start_datetime, "d")) {
        return hour > +moment(this.filter.start_datetime).format("H");
      } else {
        return true;
      }
    },
    setDefaultFilter(filterValue) {
      this.filterOptions = filterValue;
    },
    getBorrowTableInterface(childInterface) {
      this.BorrowListTableFunction = childInterface;
    },
    handleFilter(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.loadAsset();
    },
    handleClear(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.filter.text = "";
      this.loadAsset();
    },
    showAssetNote(assetDetail) {
      this.noteModal = true;
      this.assetDetailInModal = {
        not_available_note: assetDetail?.not_available_note || "-",
      };
    },
    toRemoveBorrowAsset() {
      this.$router.push({ name: "remove-borrow" });
    },
    createOrder() {
      this.$store.dispatch("borrow/clearBorrowState");
      this.$router.push({ name: "borrow-order" });
    },
    handleStatusFilter(status) {
      this.filter.request_status = status;
      this.loadAsset();
    },
    async loadAsset() {
      this.BorrowListTableFunction.showLoading();
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.id_company = id_company;

      const response = await axios.get(
        `${borrowUrl()}asset/list/company/id/${id_company}`,
        {
          params: {
            ...this.filter,
            ...this.filterOptions,
            page: this.currentPage,
            limit: this.perPage,
          },
          ...authHeader(),
        }
      );
      this.tableData = response.data.results;
      this.totalRows = response.data.item_count;
      this.BorrowListTableFunction.hideLoading();
    },

    removeSelectedAsset(assetId) {
      const index = this.selectedAsset.findIndex(
        (asset) => asset.asset_id === assetId
      );
      this.selectedAsset.splice(index, 1);
    },
  },
  created() {
    this.loadAsset();
  },
};
</script>

<style lang="scss" scoped>
.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
  &.disabled {
    color: #dcdcdc;
    border-color: #dcdcdc;
  }
}
#dropdown-1 {
  min-width: 200px;
  ::v-deep button {
    outline: none !important;
    background: #f4f4f4 !important;
    border: 2px solid #d2d2d2;
    border-radius: 8px;
    color: #000;
    border-radius: 8px;
  }
  p {
    margin: 0px;
    padding: 5px 0;
    font-size: 14px;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
    width: 40px;
  }
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
</style>
